import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import UpcomingProjectOne from "@/components/upcoming-project-one";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

const UpcomingProjectPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Event Page">
          <HeaderOne />
          <PageBanner title="Upcoming Events" name="Upcoming Events" imgClass='pageBannerProjects'/>
          <UpcomingProjectOne />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default UpcomingProjectPage;
