import React from "react";
import { LogoImage } from "@/data";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
              Bharatheeyam was initially introduced in 2009. The initial idea was to celebrate India's Independence thru a Patriotic Song Competition within Rotary Clubs in Kochi City.
              </p>
              <p>&nbsp;</p>
              <p>
              Rotary Club of Cochin Harbour chartered in 4th March, 1985 with Charter No: 0022452.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <p>
                Rotary Cochin Harbour
                <br />
                Taj Vivanta, Kochi
              </p>
              <p>Phone:</p>
              <p>Rtn. Aju Jacob George <br/>President - +91 96567 00000</p>
              <p>Rtn. Blaze K Jose <br/>Secretary - +91 94474 87767</p>
              <p>Email:</p>
              <p>
                <a href="#">rotarycochinharbour@gmail.com</a>
              </p>
            </aside>
          </div>
          {/* <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>Twitter
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-youtube-play"></i>Youtube
                  </a>
                </li>
              </ul>
            </aside>
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} by{" "}
              <a href="/">Rotaryharbourbharatheeyam.in</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
